
import { ref } from 'vue'
import { unsubmittedHomeworkCourseList } from '@/api/user/student'
import { CourseSeriesWithCourses } from '@/api/user/student/types'
import CourseList from '../components/CourseExpandableList.vue'
import { expandState } from './state'
import { Course } from 'momai'

export default {
  components: {
    CourseList
  },
  setup: () => {
    const unsubmittedHomeworkCourses = ref<CourseSeriesWithCourses<Course>[]>([])

    unsubmittedHomeworkCourseList().then(r => {
      unsubmittedHomeworkCourses.value = r
    })
    return {
      expandState,
      unsubmittedHomeworkCourses
    }
  }
}
