
import { defineComponent, PropType, ref, watch } from 'vue'
import { CourseSeriesWithCourses } from '@/api/user/student/types'
import { Course } from 'momai'

export default defineComponent({
  props: {
    list: {
      type: Array as PropType<CourseSeriesWithCourses<Course>[]>,
      required: true
    },
    value: {
      type: [Number, String],
      require: true
    }
  },

  setup: (props, { emit }) => {
    const expandedCourseSeries = ref(props.value)
    watch(expandedCourseSeries, (newValue) => {
      emit('update:value', newValue)
    })

    watch(() => props.value, newValue => {
      expandedCourseSeries.value = newValue
    })

    return {
      expandedCourseSeries
    }
  }
})
